<template>
  <div>
    <div class="search-box">
      <div class="search-logo">
        <img src="@/assets/prpru-logo.png" alt="" />
      </div>
      <div class="search-form">
        <form v-on:submit.prevent="handleSearch()">
          <input
            v-model="searchKey"
            type="text"
            id="input"
            class="search-input"
            autocomplete="false"
          />
        </form>
      </div>
      <ul>
        <li><a href="https://posts.mahoneko.com">PRPRU导航</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: null,
    };
  },
  methods: {
    handleSearch() {
      if (this.searchKey === null) {
        return;
      }
      this.$router.push({ name: "Search", query: { keyword: this.searchKey } });
    },
  },
};
</script>

<style>
.search-box {
  width: 600px;
  margin: 0 auto;
  margin-top: 200px;
}

.search-logo {
  text-align: center;
}

.search-form {
  margin-top: 20px;
}

.search-logo img {
  width: 400px;
}

.search-input {
  width: 600px;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
  line-height: 40px;
  padding: 0 20px;
}
</style>
